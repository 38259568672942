import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/avantages.css'

import medal from './../assets/img/medal.svg'
import onlyLogoHome from './../assets/img/only_logo_home.svg'
import subtitleWhite from './../assets/img/subtitle_white_stroke.svg'
import subtitleBlue from './../assets/img/subtitle_blue_stroke.svg'
import whiteArrow from './../assets/img/white_arrow.svg'
import avantagesHospitalResponsive from './../assets/img/avantages_hospital_responsive.svg'
import checkbox from './../assets/img/checkbox.svg'
import avantagesHospital from './../assets/img/avantages_hospital.svg'
import avantagesVehicles from './../assets/img/avantages_vehicles.svg'
import wCheckbox from './../assets/img/w_checkbox.svg'
import brands from './../assets/img/brands.png'
import littleEmblemBlue from './../assets/img/little_emblem_blue.svg'

const Avantages = () => {
  return (
    <>
      <Helmet>
        <title>Avantages | Paramedic</title>
      </Helmet>
      <section id='section_avantages_home'>
        <div className='container-fluid'>
          <div className='row' id='home-principal-row'>
            <div className='col-lg-6 left_home'>
              <img
                draggable='false' data-aos='fade-right' data-aos-duration='1000' src={medal}
                alt="Illustration d'une médaille avec emblème Paramedic"
              />
            </div>
            <div className='col-lg-6 right_home'>
              <div className='row'>
                <img
                  draggable='false' src={onlyLogoHome} alt='Logo Paramedic Blanc'
                  className='logo_home' data-aos='fade-left' data-aos-duration='1000'
                />
              </div>
              <h1 data-aos='fade-left' data-aos-duration='1000' data-aos-delay='150'>Nos avantages</h1>
              <img
                draggable='false' src={subtitleWhite}
                alt='Traits blancs à bouts arrondis' className='subtitle_white_stroke' data-aos='fade-left'
                data-aos-duration='1000' data-aos-delay='150'
              />
              <p data-aos='fade-left' data-aos-duration='1000' data-aos-delay='300'>Paramedic s’adresse à
                l’ensemble des acteurs de la chaîne du transport sanitaire et leur propose des solutions
                adaptées tant d’un point de vue financier qu’ organisationnel.
              </p>
              <Link to='#section_avantages_etablissement'>
                <img
                  draggable='false' src={whiteArrow}
                  alt='Flèche blanche orientée vers le bas'
                  className='white_arrow slide-bottom-faster'
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id='section_avantages_etablissement'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-7 eta-left'>
              <h4 data-aos='fade-down' data-aos-duration='1000'>Les établissement de santé</h4>
              <img
                draggable='false' src={subtitleBlue} alt='Traits bleus à bouts arrondis'
                className='subtitle_blue_stroke' data-aos='fade-down' data-aos-duration='1000'
              />
              <div className='col-md-12'>
                <img
                  draggable='false' src={avantagesHospitalResponsive}
                  alt="Illustration d'hôpital" id='avantages_hospital_responsive' data-aos='fade-left'
                  data-aos-duration='1000'
                />
              </div>
              <p className='title-text' data-aos='fade-down' data-aos-duration='1000'>Paramedic permet aux structures
                de soins<br />de se détacher de la gestion des transports sanitaires,<br />de la prise de commande à
                la facturation.
              </p>
              <ul data-aos='fade-right' data-aos-duration='1000'>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Outil intuitif et simple d’utilisation</p>
                </li>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Réduction de la charge de travail</p>
                </li>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Attributions des transports quasi-instantanées</p>
                </li>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Assistance humaine</p>
                </li>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Gestion administrative simplifiée</p>
                </li>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Optimisation du budget</p>
                </li>
                <li>
                  <img
                    draggable='false' src={checkbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Amélioration du parcours de santé. </p>
                </li>
              </ul>
              <Link to='/form_sante' className='left-button'>Souscrire en tant qu'établissement de santé</Link>
            </div>
            <div className='col-lg-5 eta-right'>
              <img
                draggable='false' src={avantagesHospital} alt="Illustration d'hôpital"
                id='avantages_hospital' data-aos='fade-left' data-aos-duration='1000'
              />
            </div>
          </div>
        </div>
      </section>

      <section id='section_avantages_transport'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-5 tport-left' data-aos='fade-right' data-aos-duration='1000'>
              <img
                draggable='false' src={avantagesVehicles}
                alt="Illustration d'un taxi et d'une ambulance"
              />
            </div>
            <div className='col-lg-6 tport-right'>
              <h4 data-aos='fade-down' data-aos-duration='1000'>Sociétés de transports sanitaires</h4>
              <img
                draggable='false' src={subtitleBlue} alt='Traits bleus à bouts arrondis'
                className='subtitle_blue_stroke' data-aos='fade-down' data-aos-duration='1000'
              />
              <p className='title-text' data-aos='fade-down' data-aos-duration='1000'>Paramedic travaille aux côtés
                des transporteurs afin de d’optimiser<br />leurs plannings et diminuer ainsi les transports à
                vide.
              </p>
              <ul data-aos='fade-left' data-aos-duration='1000'>
                <li>
                  <img
                    draggable='false' src={wCheckbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Transports pertinants</p>
                </li>
                <li>
                  <img
                    draggable='false' src={wCheckbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Ordonnancement</p>
                </li>
                <li>
                  <img
                    draggable='false' src={wCheckbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Optimisation de la flotte</p>
                </li>
                <li>
                  <img
                    draggable='false' src={wCheckbox} alt='Cercle de validation bleu'
                    className='checkbox'
                  />
                  <p>Assistance humaine</p>
                </li>
              </ul>
              <Link to='/transport' className='left-button'>Souscrire en tant que société d’ambulance</Link>
            </div>
          </div>
        </div>
      </section>

      <section id='section_confiance'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 conf-title' data-aos='fade-down' data-aos-duration='1000'>
              <h4>Il nous ont fait confiance</h4>
              <img
                draggable='false' src={subtitleBlue} alt='Traits bleus à bouts arrondis'
                className='subtitle_blue_stroke'
              />
            </div>
            <div className='col-lg-9 col-md-10 conf-brands' data-aos='fade-right' data-aos-duration='1000'>
              <img draggable='false' style={{ width: '100%' }} src={brands} alt='Logo de marques travaillant avec Paramedic' />
            </div>
          </div>
          <div className='row rw-emblem'>
            <div className='col-lg-1 col-emblem'>
              <img
                draggable='false' src={littleEmblemBlue} alt='Emblème bleue de Paramédic'
                className='rotating'
              />
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Avantages
